import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Membership at Archibald James" description="Membership at Archibald James" mdxType="SEO" />
    <article className="prose mx-auto">
      <h1 {...{
        "id": "membership"
      }}>{`Membership`}</h1>
      <p>{`Members enjoy the best of Archibald James.`}</p>
      <p><a parentName="p" {...{
          "href": "/club"
        }}>{`Check out the club details here`}</a>{`.`}</p>
      <p><a parentName="p" {...{
          "href": "/membership/manage"
        }}>{`Manage your membership`}</a>{`.`}</p>
      <p><a parentName="p" {...{
          "href": "/membership/faq"
        }}>{`FAQ`}</a></p>
      <p><a parentName="p" {...{
          "href": "/contact"
        }}>{`Contact us`}</a></p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      